import { graphql, Link } from "gatsby";
import React, {useState, useEffect}  from "react";
import Layout from "~/templates/Layout";
import { MediaImage } from '~/components/elements/Media';
import { Share, LinkedIn, Twitter, Facebook } from '~/components/elements/Icon';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { SliderInner } from '~/components/landing/Resources';
import useBlogPosts from "~/hooks/useBlogPosts";

import Seo from 'gatsby-plugin-wpgraphql-seo';
import { Newsletter } from "~/components/flexible/Newsletter";
import { Logo } from "../components/global/Header";

export default function articlePage({
  data: { wpPage, wpPost, wp },
}: {
  data: { wpPage: { page } };
}) {

  return (
    <Layout wp={wp} headerColor="light-blue">
      <Seo post={wpPost} title={wpPost.title} />
      <Masthead {...wpPost} />
      <Content {...wpPost} />
      <RelatedArticles />
      <Newsletter />
    </Layout>
  );
}

export const Masthead = (props) => {

  const { categories, title, uri, link, postTypePost, featuredImage } = props
  const authorDetails = postTypePost.authorDetails;

  const [pageUrl, setPageUrl] = React.useState('');
  useEffect(() => {
    setPageUrl(window?.location?.href);
  }, []);

  const mainImage = featuredImage ? featuredImage?.node : postTypePost?.previewImage;
  return (
    <section className="section section-top relative">
      <div className="absolute top-0 left-0 right-0 h-1/2 bg-blue-light"></div>
      <div className="container relative z-[1]">

        <div className="space-y-16">
          <div className="text-center max-w-[720px] mx-auto space-y-3">
            {categories?.nodes[0]?.name && (
              <h6 className="font-body font-semibold t-14-upper tracking-[.16em] text-blue ">{categories?.nodes[0]?.name}</h6>
            )}
            <h1 className="t-50">{title}</h1>
            {postTypePost?.previewSummary && <p className="t-20 t-black-65">{postTypePost?.previewSummary}</p>}
          </div>
          <div className="space-y-4">
            
            {postTypePost?.previewVideo ? (
              <div className="aspect-w-16 aspect-h-9 rounded-[15px] overflow-hidden transform-gpu bg-navy bg-opacity-10">
                {postTypePost?.previewVideo && (
                  <video src={postTypePost?.previewVideo?.localFile?.publicURL} className="bg-navy bg-opacity-10" controls  />
                )}
              </div>
            ) : (postTypePost?.bannerImage || mainImage) && (
              <div className="aspect-w-3 aspect-h-1 rounded-[15px] overflow-hidden transform-gpu bg-navy bg-opacity-10">
                {postTypePost?.bannerImage ? (
                  <MediaImage image={postTypePost?.bannerImage} className="!absolute" objectFit="cover" />
                ) : mainImage && (
                  <MediaImage image={mainImage} className="!absolute" objectFit="cover" />
                )}
              </div>
            )}

              
            
            <div className="bg-blue-light rounded-[15px] px-10 py-8 flex flex-col items-center space-y-5 md:space-y-0 md:items-center md:flex-row md:justify-between">
              <div className="flex items-center space-x-4">
                <Author {...authorDetails} />
              </div>
              <div className="flex items-center space-x-7">
                <div className="flex space-x-2 items-center text-[#595959]">
                  <div>Share</div>
                  <Share />
                </div>
                <div className="bg-white border border-[#CED9E2] rounded p-2 py-3">
                <div className={`flex items-start space-x-2.5 `}>

                  <LinkedinShareButton url={pageUrl}>
                    <div className="w-[60px] h-5  flex items-center justify-center group transition-colors duration-300 ease-in-out group border-r border-r-[#CED9E2]">
                      <div className="w-4 h-4 fw-icon text-navy group-hover:text-blue">
                        <LinkedIn />
                      </div>
                    </div>
                  </LinkedinShareButton>
                  <FacebookShareButton url={pageUrl}>
                    <div className="w-[60px] h-5  flex items-center justify-center group transition-colors duration-300 ease-in-out group border-r border-r-[#CED9E2]">
                      <div className="w-4 h-4 fw-icon text-navy group-hover:text-blue">
                        <Facebook />
                      </div>
                    </div>
                  </FacebookShareButton>
                  <TwitterShareButton url={pageUrl}>
                    <div className="w-[60px] h-5  flex items-center justify-center group transition-colors duration-300 ease-in-out group ">
                      <div className="w-4 h-4 fw-icon text-navy group-hover:text-blue">
                        <Twitter />
                      </div>
                    </div>
                  </TwitterShareButton>
                </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


export const Content = (props) => {

  const { content } = props

  if(!content) return null

  return (
    <section className="section section-bot">
      <div className="container">
        <div className="prose max-w-[770px] mx-auto" dangerouslySetInnerHTML={{__html: content }} />
      </div>
    </section>
  )
}


export const RelatedArticles = () => {

  const { allWpPost } = useBlogPosts();

  const results = []

	// allWpPost?.nodes?.map((result) => (
	// 	results.push(result.post)
	// ))

  return (
    <section className="section">
      <div className="container overflow-hidden">
        <SliderInner heading="Learn more about Yes by reading our blog" link={{url: '/resources', title: 'See all articles'}} results={allWpPost?.nodes} />
      </div>
    </section>
  )
}

export const Author = ({fullName, jobTitle, image}) => {
  return (
    <>
      {image && (
        <div className="w-[58px] h-[58px] rounded-full overflow-hidden bg-navy border-2 border-white">
          <MediaImage image={image} />
        </div>
      )}
      <div className="">
        {fullName ? (
          <>
            <div className="font-semibold text-navy t-17">{fullName || "YesHearing" }</div>
            {jobTitle && <div className="text-navy text-opacity-60 t-17">{jobTitle}</div> }
          </>
        ) : (
          <>
            <Logo />
          </>
        )}
        
      </div>
    </>
  )
}




export const pageQuery = graphql`
  query Article($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wpPost(id: { eq: $id }) {
      ...GeneratedWpPost
    }
    wp {
      ...GeneratedWp
    }
  }
`;
